import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        searchSingerList: [],// 侧边栏搜索歌手
        searchStyleList: [],// 侧边栏搜索风格
        searchMusicalInstrumentsList: [],// 侧边栏搜索乐器
        searchMoodList: [],// 侧边栏搜索情绪
        searchSpeedList: [],// 侧边栏搜索速度
        tagsList: [],
        loginname: '',// 当前登陆账户
		myPurchaseStatus: 3,//我的购买状态 3全部 0待支付 1已支付
        musicInfo: {},
        historyMusicList: [],
        isBoss: '',// 是否是leader 0不是 1是
		groupId: '',
        identity: '',//身份，1用户，2企业，3音乐人
		userImage: 'https://www.7keymusic.com/img/noheadimg.04b488c8.png',//头像
		introduce: '',//介绍
		isProve: '',//是否认证音乐人
		countryId: '',//国家id 1国内音乐人 其余都是海外音乐人
		bankList: [],//银行卡列表
		purchaseType: 1,//1已购买 2已锁定
		title: '',//搜索关键词
		playShow: false,
		currentTime: '',//音乐播放器当前时间
		headerSelectName: '/recommend', // 头部选择
		myInfo: {},
		keywords: '',
		groupType: '',
		purchasedStatus: 1,//1已支付 0未支付 2已过期
    },
	mutations: {
		changeSingerValue(state, value) {
			state.searchSingerList = value
		},
		changeStyleValue(state, value) {
			state.searchStyleList = value
		},
		changeMusicalInstrumentsValue(state, value) {
			state.searchMusicalInstrumentsList = value
		},
		changeMoodValue(state, value) {
			state.searchMoodList = value
		},
		changeSpeedValue(state, value) {
			state.searchSpeedList = value
		},
		changeTagList(state, value) {
			state.tagsList = value
		},
		changeHowlerObj(state, value) {
			state.howlerObj = value
		},
		// // 切换已锁定音乐支付状态
		// changePurchaseStatus(state, value) {
		// 	state.myPurchaseStatus = value
		// },
        changeMusicInfo(state, value) {
            state.musicInfo = value
        },
        changeHistoryMusicList(state, value) {
            state.historyMusicList = value
        },
		changeHeaderSelectName(state, value) {
            state.headerSelectName = value
        },
    },
    actions: {},
    modules: {}
})
